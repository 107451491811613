import React from "react"
import { useState, useEffect } from "react"
import Anime from "react-anime"
import styled from "@emotion/styled"

const MyAnime = (props) => {
  return (
    <Anime
      easing="easeOutQuad"
      duration={4000}
      loop={false}
      svg
      complete={props.charFinishFunc}
      component="g"
      delay={(el, index) => index * 500}
      direction="normal"
      strokeDashoffset={(el) => {
        let pathLength = "0"
        for (const key in el.children) {
          let child = el.children[key]
          if (child.getTotalLength) {
            pathLength = child.getTotalLength().toString()
            el.setAttribute("stroke-dasharray", pathLength)
          }
        }
        return [pathLength, 0]
      }}
      {...props}
    />
  )
}

const StyledLoader = styled.div`
  opacity: ${(props) => (props.isMounted ? 1 : 0)};
`

export default function Logo({ finishFunc }) {
  const [isMounted, setIsMounted] = useState(false)
  let numCharDone = 0

  // Temp solution
  const animDone = () => {
    numCharDone += 1
    if (numCharDone === 5) {
      // console.log("fading in two seconds")
      setTimeout(() => finishFunc(), 1500)
    }
  }

  const defaultStyles = {
    fill: "none",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "2px",
  }

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 10)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <Anime
      translateX={[0, 20]}
      opacity={[1, 0]}
      easing={"easeInCubic"}
      delay={6200}
      duration={200}
    >
      <StyledLoader isMounted={isMounted}>
        <svg
          fill="none"
          height="130"
          viewBox="0 0 138 63"
          width="278"
          xmlns="http://www.w3.org/2000/svg"
          // css={ZoomOutCSS}
        >
          <g
            id="j"
            style={{
              stroke: "#114354",
              ...defaultStyles,
            }}
          >
            <MyAnime charFinishFunc={animDone}>
              <circle cx="25.5" cy="4.5" r="4.5" />
              <path d="m21 43h32v9h-32z" transform="matrix(0 -1 1 0 -22 64)" />
              <path d="m15 58c1.9698 0 3.9204-.388 5.7403-1.1418 1.8198-.7538 3.4734-1.8587 4.8663-3.2516s2.4978-3.0465 3.2516-4.8664c.7538-1.8198 1.1418-3.7704 1.1418-5.7402h-9c0 .7879-.1552 1.5681-.4567 2.2961s-.7435 1.3894-1.3007 1.9465c-.5571.5572-1.2185.9991-1.9465 1.3007-.728.3015-1.5082.4567-2.2961.4567z" />
            </MyAnime>
          </g>
          <g
            id="n"
            style={{
              stroke: "#a7dbed",
              ...defaultStyles,
            }}
          >
            <MyAnime charFinishFunc={animDone}>
              <path d="m48 42h16v9h-16z" transform="matrix(0 -1 1 0 6 90)" />
              <path d="m57 26c0-1.9698-.388-3.9204-1.1418-5.7403-.7538-1.8198-1.8587-3.4734-3.2516-4.8663s-3.0465-2.4978-4.8663-3.2516c-1.8199-.7538-3.7705-1.1418-5.7403-1.1418s-3.9204.388-5.7402 1.1418c-1.8199.7538-3.4735 1.8587-4.8664 3.2516s-2.4978 3.0465-3.2516 4.8663c-.7538 1.8199-1.1418 3.7705-1.1418 5.7403h9c0-.7879.1552-1.5681.4567-2.2961.3016-.728.7435-1.3894 1.3007-1.9465.5571-.5572 1.2185-.9992 1.9465-1.3007s1.5082-.4567 2.2961-.4567 1.5681.1552 2.2961.4567c.728.3016 1.3894.7435 1.9465 1.3007.5572.5571.9992 1.2185 1.3007 1.9465s.4567 1.5082.4567 2.2961z" />
              <path d="m27 42h16v9h-16z" transform="matrix(0 -1 1 0 -15 69)" />
            </MyAnime>
          </g>
          <g
            id="g"
            style={{
              stroke: "#847389",
              ...defaultStyles,
            }}
          >
            <MyAnime charFinishFunc={animDone}>
              <path d="m54 48c0 3.9782 1.5804 7.7936 4.3934 10.6066s6.6284 4.3934 10.6066 4.3934 7.7936-1.5804 10.6066-4.3934 4.3934-6.6284 4.3934-10.6066h-9c0 1.5913-.6321 3.1174-1.7574 4.2426-1.1252 1.1253-2.6513 1.7574-4.2426 1.7574s-3.1174-.6321-4.2426-1.7574c-1.1253-1.1252-1.7574-2.6513-1.7574-4.2426z" />
              <path d="m75 48h22v9h-22z" transform="matrix(0 -1 1 0 27 123)" />
              <path d="m54 26c0-8.2843 6.7157-15 15-15s15 6.7157 15 15-6.7157 15-15 15-15-6.7157-15-15zm21 0c0-3.3137-2.6863-6-6-6s-6 2.6863-6 6 2.6863 6 6 6 6-2.6863 6-6z" />
            </MyAnime>
          </g>
          <g
            id="a"
            style={{
              stroke: "#2d080a",
              ...defaultStyles,
            }}
          >
            <MyAnime charFinishFunc={animDone}>
              <path d="m111 26c0-2.9667-.88-5.8668-2.528-8.3336-1.648-2.4667-3.991-4.3893-6.732-5.5246-2.7406-1.1353-5.7566-1.4324-8.6664-.8536-2.9097.5788-5.5824 2.0074-7.6802 4.1052s-3.5264 4.7705-4.1052 7.6802c-.5788 2.9098-.2817 5.9258.8536 8.6666 1.1353 2.7409 3.0579 5.0836 5.5246 6.7318 2.4668 1.6483 5.3669 2.528 8.3336 2.528v-9c-1.1867 0-2.3467-.3519-3.3334-1.0112s-1.7558-1.5963-2.2099-2.6927-.5729-2.3028-.3414-3.4666c.2315-1.1639.8029-2.233 1.6421-3.0721.8391-.8392 1.9082-1.4106 3.0721-1.6421 1.1638-.2315 2.3702-.1127 3.4666.3414s2.0339 1.2232 2.6929 2.2099 1.011 2.1467 1.011 3.3334z" />
            </MyAnime>
          </g>
          <g
            id="i"
            style={{
              stroke: "#fac668",
              ...defaultStyles,
            }}
          >
            <MyAnime charFinishFunc={animDone}>
              <circle cx="112.5" cy="4.5" r="4.5" />
              <path d="m108 26c0 1.9698.388 3.9204 1.142 5.7403.754 1.8198 1.859 3.4734 3.251 4.8663 1.393 1.3929 3.047 2.4978 4.867 3.2516s3.77 1.1418 5.74 1.1418v-9c-.788 0-1.568-.1552-2.296-.4567-.728-.3016-1.389-.7435-1.947-1.3007-.557-.5571-.999-1.2185-1.3-1.9465-.302-.728-.457-1.5082-.457-2.2961z" />
              <path
                d="m117 11h15v9h-15z"
                transform="matrix(0 1 -1 0 128 -106)"
              />
            </MyAnime>
          </g>
        </svg>
      </StyledLoader>
    </Anime>
  )
}
