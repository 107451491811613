import React from "react"
import Emoji from "./Emoji"
import styled from "@emotion/styled"

// Footer Icons
import { AiFillLinkedin } from "@react-icons/all-files/ai/AiFillLinkedin"
import { AiFillGithub } from "@react-icons/all-files/ai/AiFillGithub"
import { AiOutlineMail } from "@react-icons/all-files/ai/AiOutlineMail"

const GatsbyLink = styled.a`
  margin-left: 20px;
  margin-right: 20px;
  font-size: 30px;
`
const FooterWrapper = styled.footer`
  text-align: center;
  justify-content: center;
  margin: 0 0 3rem 0;
`
const FooterCopyright = styled.p`
  margin: 0 0 2rem 0;
`
const FooterHandle = styled.div`
  display: flex;
  justify-content: center;
`

export default function Footer() {
  return (
    <FooterWrapper>
      <FooterCopyright>
        Designed with <Emoji symbol="❤️" label="heart" /> by Justin Ngai{" "}
        <Emoji symbol="©" label="copyright" /> 2023
      </FooterCopyright>
      <FooterHandle>
        <GatsbyLink href="https://github.com/NgaiJustin">
          <AiFillGithub />
        </GatsbyLink>
        <GatsbyLink href="mailto:justinngai238@gmail.com">
          <AiOutlineMail />
        </GatsbyLink>
        <GatsbyLink href="https://www.linkedin.com/in/ngaijustin/">
          <AiFillLinkedin />
        </GatsbyLink>
      </FooterHandle>
    </FooterWrapper>
  )
}
