import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import Logo from "../AnimatedLogo/AnimatedLogo"
import styled from "@emotion/styled"

const StyledLoader = styled.div`
  /* Center */
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #a7dbed; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #fffde4,
    #a7dbed
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #fffde4,
    #a7dbed
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  z-index: 1000;
`

const LandingAnim = ({ finishLoading }) => {
  return (
    <StyledLoader className="loader">
      <Helmet bodyAttributes={{ class: `hidden` }} />
      <Logo finishFunc={finishLoading} />
    </StyledLoader>
  )
}

LandingAnim.propTypes = {
  finishLoading: PropTypes.func.isRequired,
}

export default LandingAnim
