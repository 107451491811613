/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import LandingAnim from "../LandingAnim/LandingAnim"
import Head from "../Head"
// import Header from "../header"
import Footer from "../Footer"

import "./layout.css"

const Content = styled.div`
  margin: 0 auto;
  max-width: 860px;
  padding: 0 1.0875rem 1rem;
  padding-top: 0;

  @media (max-width: 870px) {
    max-width: 600px;
    /* transition: all 1s ease 0s; */
  }
  @media (max-width: 600px) {
    max-width: 450px;
    /* transition: all 1s ease 0s; */
  }
`

const Layout = ({ children, location }) => {
  // Loading animation handling inspired by https://brittanychiang.com/
  const isHome = location.pathname === "/"
  const [isLoading, setIsLoading] = useState(isHome)

  // Sets target="_blank" rel="noopener noreferrer" on external links
  const handleExternalLinks = () => {
    const allLinks = Array.from(document.querySelectorAll("a"))
    if (allLinks.length > 0) {
      allLinks.forEach((link) => {
        if (link.host !== window.location.host) {
          link.setAttribute("rel", "noopener noreferrer")
          link.setAttribute("target", "_blank")
        }
      })
    }
  }

  useEffect(() => {
    if (isLoading) {
      return
    }
    if (location.hash) {
      const id = location.hash.substring(1) // location.hash without the '#'
      setTimeout(() => {
        const el = document.getElementById(id)
        if (el) {
          el.scrollIntoView()
          el.focus()
        }
      }, 0)
    }

    handleExternalLinks()
  }, [isLoading, location.hash])

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => (
        <>
          <Head />
          <div id="root">
            {isLoading && isHome ? (
              <LandingAnim finishLoading={() => setIsLoading(false)} />
            ) : (
              <Content>
                <main>{children}</main>
                <Footer />
              </Content>
            )}
          </div>
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
}

export default Layout
